import { STATUS_TYPES } from '_atoms/Status';

export const headerTabs = ['dashboard', 'jobs', 'candidates'];
export const levels = ['No Level', 'Entry', 'Junior', 'Middle', 'Senior', 'Lead'];
export const languageOptions = ['English', 'German', 'Chinese (Mandarin)', 'Spanish', 'French'];
export const levelOptions = [
  'Beginner',
  'Pre-Intermediate',
  'Intermediate',
  'Upper-Intermediate',
  'Advanced',
  'Native',
];
export const usersLimitOnPage = 30;

export const latinRegexp = /^[A-Za-z0-9 -]*$/;
export const emailRegexp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegexp = /[^0-9+]/;
export const urlRegexp =
  /^(https:\/\/www\.|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
export const linkRegexp =
  '^(https?:\\/\\/)?' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
  '((\\d{1,3}\\.){3}\\d{1,3}))' +
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
  '(\\?[;&a-z\\d%_.~+=-]*)?' +
  '(\\#[-a-z\\d_]*)?$';

export const STEPS_LABELS = {
  CONTACT_WITH_CANDIDATE: 'Contact with candidate',
  CV_SCREENING: 'CV screening',
  TECHNICAL_INTERVIEW: 'Technical interview',
  MANAGEMENT_INTERVIEW: 'Management interview',
  MANAGEMENT_APPROVAL: 'Management approval',
  OFFER: 'Offer',
  JOB_STARTED: 'Job started',
};

export const SOCIAL_NETWORKS = {
  LINKEDIN: 'LinkedIn',
  FACEBOOK: 'Facebook',
  TWITTER: 'Twitter',
  STACK: 'StackOverflow',
  GITLAB: 'Gitlab',
  VK: 'VK',
  INSTAGRAM: 'Instagram',
  DRIBBBLE: 'Dribbble',
  BEHANCE: 'Behance',
  LINK: 'Link',
};

export const probationPeriod = [
  {
    id: 0,
    label: '1 month',
    value: 1,
  },
  {
    id: 1,
    label: '3 month',
    value: 3,
  },
  {
    id: 2,
    label: 'Other',
    value: '',
  },
];

export const candidateLevels = [
  {
    id: 0,
    label: 'No Level',
    value: 'No Level',
  },
  {
    id: 1,
    label: 'Entry',
    value: 'Entry',
  },
  {
    id: 2,
    label: 'Junior',
    value: 'Junior',
  },
  {
    id: 3,
    label: 'Middle',
    value: 'Middle',
  },
  {
    id: 4,
    label: 'Senior',
    value: 'Senior',
  },
  {
    id: 5,
    label: 'Lead',
    value: 'Lead',
  },
  {
    id: 6,
    label: 'Custom',
    value: 'Custom',
  },
];

export const titleOwnTasks = 'My Tasks';
export const titleInterviews = 'Upcoming Interviews';
export const titleActiveJobs = 'Active jobs';
export const titleReminders = 'Reminders';
export const titleNewJobs = 'New Jobs';
export const titleTasks = 'Tasks';
export const possibleTime = [
  { value: '00:00', label: '00:00' },
  { value: '00:30', label: '00:30' },
  { value: '01:00', label: '01:00' },
  { value: '01:30', label: '01:30' },
  { value: '02:00', label: '02:00' },
  { value: '02:30', label: '02:30' },
  { value: '03:00', label: '03:00' },
  { value: '03:30', label: '03:30' },
  { value: '04:00', label: '04:00' },
  { value: '04:30', label: '04:30' },
  { value: '05:00', label: '05:00' },
  { value: '05:30', label: '05:30' },
  { value: '06:00', label: '06:00' },
  { value: '06:30', label: '06:30' },
  { value: '07:00', label: '07:00' },
  { value: '07:30', label: '07:30' },
  { value: '08:00', label: '08:00' },
  { value: '08:30', label: '08:30' },
  { value: '09:00', label: '09:00' },
  { value: '09:30', label: '09:30' },
  { value: '10:00', label: '10:00' },
  { value: '10:30', label: '10:30' },
  { value: '11:00', label: '11:00' },
  { value: '11:30', label: '11:30' },
  { value: '12:00', label: '12:00' },
  { value: '12:30', label: '12:30' },
  { value: '13:00', label: '13:00' },
  { value: '13:30', label: '13:30' },
  { value: '14:00', label: '14:00' },
  { value: '14:30', label: '14:30' },
  { value: '15:00', label: '15:00' },
  { value: '15:30', label: '15:30' },
  { value: '16:00', label: '16:00' },
  { value: '16:30', label: '16:30' },
  { value: '17:00', label: '17:00' },
  { value: '17:30', label: '17:30' },
  { value: '18:00', label: '18:00' },
  { value: '18:30', label: '18:30' },
  { value: '19:00', label: '19:00' },
  { value: '19:30', label: '19:30' },
  { value: '20:00', label: '20:00' },
  { value: '20:30', label: '20:30' },
  { value: '21:00', label: '21:00' },
  { value: '21:30', label: '21:30' },
  { value: '22:00', label: '22:00' },
  { value: '22:30', label: '22:30' },
  { value: '23:00', label: '23:00' },
  { value: '23:30', label: '23:30' },
];

export const TIME_ZONE = [
  { value: 'Africa/Abidjan (GMT+00:00)', label: 'Africa/Abidjan (GMT+00:00)' },
  { value: 'Europe/London (GMT+1:00)', label: 'Europe/London (GMT+1:00)' },
  { value: 'Europe/Vienna (GMT+2:00)', label: 'Europe/Vienna (GMT+2:00)' },
  { value: 'Europe/Moscow (GMT+3:00)', label: 'Europe/Moscow (GMT+3:00)' },
  { value: 'Asia/Tbilisi (GMT+4:00)', label: 'Asia/Tbilisi (GMT+4:00)' },
  { value: 'Asia/Tashkent (GMT+5:00)', label: 'Asia/Tashkent (GMT+5:00)' },
  { value: 'India/New_Delhi (GMT+5:30)', label: 'India/New_Delhi (GMT+5:30)' },
  { value: 'Asia/Almaty (GMT+6:00)', label: 'Asia/Almaty (GMT+6:00)' },
  { value: 'Asia/Jakarta (GMT+7:00)', label: 'Asia/Jakarta (GMT+7:00)' },
  { value: 'Hongkong (GMT+8:00)', label: 'Hongkong (GMT+8:00)' },
  { value: 'Asia/Tokyo (GMT+9:00)', label: 'Asia/Tokyo (GMT+9:00)' },
  { value: 'Australia/Canberra (GMT+10:00)', label: 'Australia/Canberra (GMT+10:00)' },
  { value: 'Russia/Sakhalin (GMT+11:00)', label: 'Russia/Sakhalin (GMT+11:00)' },
  { value: 'Fiji/Suva (GMT+12:00)', label: 'Fiji/Suva (GMT+12:00)' },
  { value: 'Atlantic/Cape_Verde (GMT-1:00)', label: 'Atlantic/Cape_Verde (GMT-1:00)' },
  { value: 'America/Nuuk (GMT-2:00)', label: 'America/Nuuk (GMT-2:00)' },
  { value: 'America/Buenos_Aires (GMT-3:00)', label: 'America/Buenos_Aires (GMT-3:00)' },
  { value: 'America/Puerto_Rico (GMT-4:00)', label: 'America/Puerto_Rico (GMT-4:00)' },
  { value: 'America/Panama (GMT-5:00)', label: 'America/Panama (GMT-5:00)' },
  { value: 'America/Mexico (GMT-6:00)', label: 'America/Mexico (GMT-6:00)' },
  { value: 'America/Boise (GMT-7:00)', label: 'America/Boise (GMT-7:00)' },
  { value: 'America/Juneau (GMT-8:00)', label: 'America/Juneau (GMT-8:00)' },
  { value: 'America/Dawson (GMT-9:00)', label: 'America/Dawson (GMT-9:00)' },
  { value: 'Pacific/Honolulu (GMT-10:00)', label: 'Pacific/Honolulu (GMT-10:00)' },
  { value: 'Pacific/Niue (GMT-11:00)', label: 'Pacific/Niue (GMT-11:00)' },
];

export const offerOptions = [
  { label: '1 month', id: 0 },
  { label: '3 months', id: 1 },
  { label: 'Other', id: 2, value: 'custom' },
];

export const STEPS = [
  { value: STEPS_LABELS.CONTACT_WITH_CANDIDATE, label: STEPS_LABELS.CONTACT_WITH_CANDIDATE },
  { value: STEPS_LABELS.CV_SCREENING, label: STEPS_LABELS.CV_SCREENING },
  { value: STEPS_LABELS.TECHNICAL_INTERVIEW, label: STEPS_LABELS.TECHNICAL_INTERVIEW },
  { value: STEPS_LABELS.MANAGEMENT_INTERVIEW, label: STEPS_LABELS.MANAGEMENT_INTERVIEW },
  { value: STEPS_LABELS.MANAGEMENT_APPROVAL, label: STEPS_LABELS.MANAGEMENT_APPROVAL },
  { value: STEPS_LABELS.OFFER, label: STEPS_LABELS.OFFER },
  { value: STEPS_LABELS.JOB_STARTED, label: STEPS_LABELS.JOB_STARTED },
];

export const stepsStatuses = {
  waitForResolution: 'Waiting for Resolution',
  rejected: 'Rejected',
  scheduleInterview: 'Schedule Interview',
  attendInterview: 'Attend Interview',
  provideFeedback: 'Provide Feedback',
  waitingForFinalDecision: 'Waiting for Final Decision',
  sendOffer: 'Send Offer',
  receiveFeedback: 'Receive Feedback',
};

export const STEP_FOR_STATUS_COMPONENT = {
  [stepsStatuses.waitForResolution]: { type: STATUS_TYPES.BLUE, children: stepsStatuses.waitForResolution },
  [stepsStatuses.rejected]: { type: STATUS_TYPES.RED, children: stepsStatuses.rejected },
  [stepsStatuses.scheduleInterview]: { type: STATUS_TYPES.GRAY, children: stepsStatuses.scheduleInterview },
  [stepsStatuses.attendInterview]: { type: STATUS_TYPES.BLUE, children: stepsStatuses.attendInterview },
  [stepsStatuses.provideFeedback]: { type: STATUS_TYPES.BLUE, children: stepsStatuses.provideFeedback },
  [stepsStatuses.waitingForFinalDecision]: { type: STATUS_TYPES.BLUE, children: stepsStatuses.waitingForFinalDecision },
  [stepsStatuses.sendOffer]: { type: STATUS_TYPES.GRAY, children: stepsStatuses.sendOffer },
  [stepsStatuses.receiveFeedback]: { type: STATUS_TYPES.BLUE, children: stepsStatuses.receiveFeedback },
  Hired: { type: STATUS_TYPES.GREEN, children: 'Hired' },
  Fired: { type: STATUS_TYPES.RED, children: 'Fired' },
};

export const STEPS_STATUSES = {
  [STEPS_LABELS.CONTACT_WITH_CANDIDATE]: [stepsStatuses.waitForResolution, stepsStatuses.rejected],
  [STEPS_LABELS.CV_SCREENING]: [stepsStatuses.waitForResolution, stepsStatuses.rejected],
  [STEPS_LABELS.TECHNICAL_INTERVIEW]: [
    stepsStatuses.scheduleInterview,
    stepsStatuses.attendInterview,
    stepsStatuses.provideFeedback,
    stepsStatuses.waitingForFinalDecision,
    stepsStatuses.rejected,
  ],
  [STEPS_LABELS.MANAGEMENT_INTERVIEW]: [
    stepsStatuses.scheduleInterview,
    stepsStatuses.attendInterview,
    stepsStatuses.provideFeedback,
    stepsStatuses.waitingForFinalDecision,
    stepsStatuses.rejected,
  ],
  [STEPS_LABELS.MANAGEMENT_APPROVAL]: [stepsStatuses.waitForResolution, stepsStatuses.rejected],
  [STEPS_LABELS.OFFER]: [stepsStatuses.sendOffer, stepsStatuses.receiveFeedback, stepsStatuses.rejected],
  [STEPS_LABELS.JOB_STARTED]: [stepsStatuses.waitForResolution, stepsStatuses.rejected],
};

export const TOOLTIP_TXT = {
  ABSOLUTE:
    'Absolute conversion is the ratio of the number of candidates at currently reviewed Job Step to the total number of candidates assigned to Jobs',
  RELATIVE:
    'Relative conversion is the ratio of the number of candidates at the currently reviewed Job step and at the previous one',
  OPENING:
    '"Opening" is a vacancy with a certain experience level, expected date and priority. 1 Opening = 1 Specialist required.',
};

export const HEADER_TABS = {
  DASHBOARD: 'Dashboard',
  JOBS: 'Jobs',
  CANDIDATES: 'Candidates',
  ANALYTICS: 'Analytics',
};

export const ANALYTICS_TABS = {
  RECRUITERS: 'recruiters',
  TEAM_LEADS: 'team leads',
  JOBS: 'jobs',
  CANDIDATES: 'candidates',
  REPORTING: 'reporting',
  OPENINGS: 'openings',
  SSC: 'self-submitted-candidates',
};

export const INITITAL_CANDIDATE_TABS = [
  { title: 'General', value: 'general' },
  { title: 'Jobs', value: 'jobs' },
];

export const EXTRA_CANDIDATE_TABS = [
  { title: 'General', value: 'general' },
  { title: 'Jobs', value: 'jobs' },
  { title: 'Timeline', value: 'timeline' },
];
