import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { TextArea, Popup } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { BUTTON_TEXT, POPUP_LABELS } from 'constants/text';
import './style.scss';

export const PopupCloseDeclineOpening = ({ open, onClose, confirm, close, opening = {}, jobName }) => {
  const [comment, setComment] = useState('');
  useEffect(() => {
    if (!open) setComment('');
  }, [open]);

  return (
    <Popup
      open={open}
      title={close ? POPUP_LABELS.CLOSE_OPENING : POPUP_LABELS.DECLINE_OPENING}
      cancelButtonProps={{ onClick: onClose, children: BUTTON_TEXT.CANCEL }}
      okButtonProps={{
        onClick: () => confirm(comment),
        disabled: !comment,
        children: close ? BUTTON_TEXT.CLOSE_OPENING : BUTTON_TEXT.DECLINE,
      }}
      className="mobile-full-size"
    >
      <div className="popup-close-opening">
        <Typography.Text className="popup-close-opening__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
          {`${opening.custom_level || opening.level} ${jobName}`}
        </Typography.Text>
        <Typography.Caption className="popup-close-opening__subtitle">
          {`Expected on ${moment(opening.expected).format('MMM DD, YYYY')}`}
        </Typography.Caption>
        <TextArea
          label={close ? 'Comment' : 'Decline reason'}
          value={comment}
          placeholder="Add a comment"
          onChange={e => setComment(e.target.value)}
        />
      </div>
    </Popup>
  );
};
