import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Divider } from 'antd';
import cn from 'classnames';
import { subject } from '@casl/ability';
import { PopupAssignToMe, PopupReassignResponsibleRecruiter } from '_molecules/index';
import { Button, BUTTON_TYPES, File, User, AtsButtonMenu } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { Status } from '_atoms/Status';
import { ACTIONS, Can, UI, useAbility } from 'permission';
import { getCandidateStepAssignment, reassignResponsibleRecruiter } from 'actions';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ReactComponent as Plane } from 'dist/icons/airplane.svg';
import { ReactComponent as Business } from 'dist/icons/business.svg';
import { ReactComponent as ChainLink } from 'dist/icons/socialNetworks/link.svg';
import { ReactComponent as Location } from 'dist/icons/location.svg';
import { ReactComponent as Clock } from 'dist/icons/clock.svg';
import { BUTTON_TEXT } from 'constants/text';
import { getStatusType } from './utils';
import './style.scss';
import { dateFormatter } from '../../helpers/dateFormatter';

export const JobCandidateInfo = ({ step, userData, author, offer, ...props }) => {
  const {
    setShowPopupDecision,
    setShowPopupWithdraw,
    openAddApprovers,
    setShowPopupFinalDecision,
    setShowPopupAssignToStep,
    setSubmitDecisionOffer,
  } = props;
  const rejected = userData.step_status === 'Rejected';
  const ability = useAbility();
  const dispatch = useDispatch();
  const { id, step: stepId, candidate } = useParams();
  const user = useSelector(getUser);
  const showAssignToMe =
    ability.can(ACTIONS.READ, UI.ASSIGN_CANDIDATE_TO_ME) && author?.uuid !== userData.responsible_recruiter?.uuid;
  const access_withdraw = ability.can(ACTIONS.READ, UI.ACTIONS_STEPS_WITHDRAW);
  const access_add_approver = ability.can(ACTIONS.READ, UI.ACTIONS_STEPS_ADD_APPROVER);
  const access_assign_to_step = ability.can(ACTIONS.READ, UI.ACTIONS_STEPS_ASSIGN_TO_STEP);
  const access_submit_final_decision = ability.can(
    ACTIONS.READ,
    subject(UI.SUBMIT_FINAL_DECISION, { step: step?.ordering_number })
  );
  const hasAccess = access_withdraw || access_add_approver || access_assign_to_step || access_submit_final_decision;

  const submitDecision = ability.can(ACTIONS.READ, UI.SUBMIT_DECISION);
  const showSubmitDecision =
    (userData.need_sent_decision || (offer && step?.ordering_number === 5)) && !rejected && submitDecision;
  const showExtraActions = !!step && Object.keys(step).length !== 0 && hasAccess;
  const [showReassignPopup, setShowReassignPopup] = useState(false);
  const [loadingReassignPopup, setLoadingShowReassignPopup] = useState(false);
  const [showAssignToMePopup, setShowAssignToMePopup] = useState(false);

  const onAssignToMe = () => {
    setShowAssignToMePopup(false);
    onReassignRecruiter(user.uuid);
  };
  const onOpenAssignToMe = () => setShowAssignToMePopup(true);
  const onCloseAssignToMe = () => setShowAssignToMePopup(false);

  const onReassign = () => setShowReassignPopup(true);
  const onCloseReassignPopup = () => setShowReassignPopup(false);
  const onReassignRecruiter = recruiter => {
    setLoadingShowReassignPopup(true);
    dispatch(reassignResponsibleRecruiter({ candidate, job: id, recruiter }, stepId, true, step?.ordering_number))
      .then(() => {
        dispatch(getCandidateStepAssignment(id, candidate, false, stepId, step?.ordering_number));
      })
      .finally(() => {
        setLoadingShowReassignPopup(false);
        onCloseReassignPopup();
      });
  };

  const onSubmitDecision = () => {
    if (offer) setSubmitDecisionOffer(true);
    else setShowPopupDecision(true);
  };

  return (
    <div className="job-candidate-profile">
      <div className={cn('job-candidate-profile__header', { actions: showExtraActions })}>
        <div className="candidate-info">
          <div className="candidate-name">
            <Link
              to={`/candidates/${userData.uuid}/show`}
              className="candidate-name__latin"
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${userData.first_name_latin} ${userData.last_name_latin}`}
            </Link>
            <Typography.Title4 className="candidate-name__local" weight={TYPOGRAPHY_WEIGHT.BOLD}>
              {`${userData.first_name_local} ${userData.last_name_local}`}
            </Typography.Title4>
          </div>
          <Status type={getStatusType(userData.step_status)}>{userData.step_status}</Status>
        </div>
        {showSubmitDecision && (
          <Button type={BUTTON_TYPES.PRIMARY} onClick={onSubmitDecision} className="submit-decision">
            {BUTTON_TEXT.SUBMIT_DECISION}
          </Button>
        )}
        {showExtraActions && (
          <AtsButtonMenu className="extra-actions">
            {access_add_approver && !offer && step.ordering_number < 5 && !rejected && (
              <li role="none" onClick={openAddApprovers}>
                Add approver
              </li>
            )}
            {access_assign_to_step && step.ordering_number !== 6 && !rejected && (
              <li
                role="none"
                onClick={() => {
                  setShowPopupAssignToStep(true);
                }}
              >
                Assign to step
              </li>
            )}
            {access_submit_final_decision && ![5, 6].includes(step.ordering_number) && !rejected && (
              <li
                role="none"
                onClick={() => {
                  setShowPopupFinalDecision(true);
                }}
              >
                Final decision
              </li>
            )}
            {access_withdraw && (
              <li
                role="none"
                onClick={() => {
                  setShowPopupWithdraw(true);
                }}
              >
                Withdraw
              </li>
            )}
          </AtsButtonMenu>
        )}
      </div>
      <div className="job-candidate-profile__body">
        <Row gutter={32}>
          <Col lg={12} md={12} sm={24}>
            <div className="info-item">
              <Typography.Label>Sources</Typography.Label>
              <Typography.Text>
                {userData.sources?.map(s => (
                  <div key={s.source}>
                    {s.other_source_name ? (
                      <>
                        <span style={{ color: '#717C8C' }}>Other: </span>
                        {s.other_source_name}
                      </>
                    ) : (
                      s.source
                    )}
                    {userData.sources.length > 1 && s.is_main && <strong> (Main)</strong>}
                  </div>
                ))}
              </Typography.Text>
            </div>
            <div className="info-item">
              <Typography.Label>Experience Level</Typography.Label>
              <Typography.Text>{userData.specialities?.[0]?.level || '-'}</Typography.Text>
            </div>
            <div className="info-item foreign-languages">
              <Typography.Label>Languages</Typography.Label>
              {!userData.foreign_languages?.length && <Typography.Text>-</Typography.Text>}
              {userData.foreign_languages?.map(el => (
                <Typography.Text key={el.language + el.level}>{`${el.language}: ${el.level}`}</Typography.Text>
              ))}
            </div>
            <div className="info-item">
              <Typography.Label>CV Links</Typography.Label>
              {!userData.cvs_links?.length && <Typography.Text>-</Typography.Text>}
              {userData.cvs_links?.map(link => (
                <a key={link} className="cv-link" href={link} target="_blank" rel="noopener noreferrer">
                  <ChainLink className="cv-link__icon" />
                  <Typography.Text>{link}</Typography.Text>
                </a>
              ))}
            </div>
          </Col>
          <Col lg={12} md={12} sm={24}>
            <div className="info-item">
              <Typography.Label>Potential opening</Typography.Label>
              {userData.potential_opening ? (
                <>
                  <Link to={`/jobs/${id}/show/openings?scroll=${userData.potential_opening.uuid}`} target="_blank">
                    {userData.potential_opening.name}
                  </Link>
                  <Typography.Caption>
                    Expected on {dateFormatter(userData.potential_opening.expected)}
                  </Typography.Caption>
                </>
              ) : (
                <Typography.Text>-</Typography.Text>
              )}
            </div>
            <div className="info-item">
              <Typography.Label>Documents</Typography.Label>
              {!userData.cvs?.length && <Typography.Text>-</Typography.Text>}
              {userData.cvs?.map(file => (
                <File key={file.file} file={file} />
              ))}
            </div>
            <div className="info-item">
              <Typography.Label>Location</Typography.Label>
              {!userData.country && !userData.timezone && <Typography.Text>-</Typography.Text>}
              {userData.country && (
                <div className="relocation-item">
                  <Location />
                  {userData.city ? `${userData.city}, ${userData.country}` : userData.country}
                </div>
              )}
              {userData.timezone && (
                <div className="relocation-item">
                  <Clock />
                  {userData.timezone}
                </div>
              )}
            </div>
            <div className="info-item">
              <Typography.Label>Relocation</Typography.Label>
              {!userData.is_ready_to_relocate && !userData.is_ready_to_business_trips && (
                <Typography.Text>-</Typography.Text>
              )}
              {userData.is_ready_to_relocate && (
                <div className="relocation-item">
                  <Plane />
                  Ready to relocate
                </div>
              )}
              {userData.is_ready_to_business_trips && (
                <div className="relocation-item">
                  <Business />
                  Ready for business trips
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Typography.Label>Responsible recruiter</Typography.Label>
        <User
          avatar={userData.responsible_recruiter?.avatar}
          fullName={userData.responsible_recruiter?.full_name}
          roles={[
            {
              name: userData.responsible_recruiter?.roles.find(
                ({ pk }) => pk === userData.responsible_recruiter.active_role
              )?.name,
            },
          ]}
        />
        <Can I={ACTIONS.READ} a={UI.EDIT_RESPONSIBLE_RECRUITER}>
          {access =>
            access && (
              <Button className="job-candidate-profile__reassign" type={BUTTON_TYPES.GHOST} onClick={onReassign}>
                reassign
              </Button>
            )
          }
        </Can>
        <Can I={ACTIONS.READ} a={UI.ASSIGN_CANDIDATE_TO_ME}>
          {showAssignToMe && (
            <Button className="job-candidate-profile__reassign" type={BUTTON_TYPES.GHOST} onClick={onOpenAssignToMe}>
              assign to me
            </Button>
          )}
        </Can>
      </div>
      <Divider />
      <PopupReassignResponsibleRecruiter
        open={showReassignPopup}
        loading={loadingReassignPopup}
        onClose={onCloseReassignPopup}
        onOk={onReassignRecruiter}
        currentRecruiter={userData.responsible_recruiter}
      />
      <PopupAssignToMe
        open={showAssignToMePopup}
        onClose={onCloseAssignToMe}
        onOk={onAssignToMe}
        candidateName={`${userData.first_name_latin} ${userData.last_name_latin}`}
      />
    </div>
  );
};
