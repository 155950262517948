import React from 'react';
import cn from 'classnames';
import { ConfigProvider, DatePicker as DatePickerAntd, Form } from 'antd';
import moment from 'moment';
import 'moment/locale/en-gb';
import locale from 'antd/lib/locale/en_GB';
import { Typography } from '_atoms/Typography';
import { ReactComponent as Calendar } from 'dist/icons/calendar.svg';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { ReactComponent as Clear } from 'dist/icons/clear.svg';
import './style.scss';

export const DatePicker = ({ className, label, minDate, maxDate, itemProps, ...props }) => (
  <div className={cn('datepicker-default-wrapper', className)}>
    {label && <Typography.Label>{label}</Typography.Label>}
    <ConfigProvider locale={locale}>
      <Form.Item {...itemProps}>
        <DatePickerAntd
          className="datepicker-default"
          suffixIcon={<Calendar />}
          prevIcon={<Vector />}
          nextIcon={<Vector className="rotate" />}
          clearIcon={<Clear />}
          allowClear
          format="MMM DD, YYYY"
          placeholder="MM/DD/YYYY"
          showToday={false}
          popupClassName="datepicker-default__calendar"
          disabledDate={d => d < moment('01/01/2000', 'MM/DD/YYYY').startOf('day') || d < minDate || d > maxDate}
          {...props}
        />
      </Form.Item>
    </ConfigProvider>
  </div>
);
