import React from 'react';
import cn from 'classnames';
import { ReactComponent as Vector } from 'dist/icons/Vector1.svg';
import { Tooltip } from '../../_atoms';
import { DECLINED_COLLAPSED, HIDE_PERCENTAGE, LAST_PERIOD, SUCCESS_TOOLTIPS } from './constants';

export const useExpandableColumn = (opened, onOpen) => ({
  title: 'Month/Transition',
  dataIndex: 'transitions',
  key: 'transitions',
  fixed: 'left',
  width: 344,
  render: (text, record) =>
    onOpen && text === 'Declined' ? (
      <div className={cn('ant-table-cell-content__with-arrow', { open: opened.find(el => el === record.id) })}>
        <Vector onClick={() => onOpen(record)} />
        {text}
      </div>
    ) : (
      <div className="ant-table-cell-content__without-arrow">{text}</div>
    ),
});

const getHiringTooltip = (block, name, month) => {
  if (block === 'CVs Submitted') {
    if (name === 'Approved') return `${month} CVs Approved / ${month} CVs Submitted`;
    if (name === 'Declined') return `${month} CVs Declined / ${month} CVs Submitted`;
    if (name === 'Waiting for Decision') return `${month} CVs Waiting for decision / ${month} CVs Submitted`;
  } else if (block === 'Interviews Scheduled') {
    if (name === 'Conducted') return `${month} Interviews Conducted / ${month} Interviews Scheduled`;
    if (name === 'Declined') return `${month} Interviews Declined by Candidate / ${month} Interviews Scheduled`;
    if (name === 'Waiting for Interview') return `${month} Awaiting Interview / ${month} Interviews Scheduled`;
  } else if (block === 'Interviews Conducted') {
    if (name === 'JOs made') return `${month} JOs made / ${month} Interviews Conducted`;
    if (name === 'Declined') return `${month} Candidates Declined/ ${month} Interviews Conducted`;
    if (name === 'Waiting for Decision') return `${month} Awaiting decision / ${month} Interviews Conducted`;
  } else {
    if (name === 'Accepted') return `${month} JOs Accepted / ${month} JOs made`;
    if (name === 'Declined') return `${month} JOs Declined / ${month} JOs made`;
    if (name === 'Waiting for Candidate’s response')
      return `${month} JOs Awaiting Candidate response / ${month} JOs made`;
  }
  return '';
};

export const hiringStatisticsDataMapper = resp =>
  resp
    .map((el, id) => {
      const getTitleData = (transitions, totalByMonth, name, idx) => {
        const data = {};
        let total = 0;
        transitions.forEach(transition => {
          transition.periods.forEach((p, index) => {
            const key = p.month + p.year;
            data[key] = totalByMonth[key] || '-';
            if (!HIDE_PERCENTAGE.includes(transition.name)) {
              if (LAST_PERIOD.includes(transition.name)) {
                if (transition.periods.length - 1 === index) {
                  total += p.confirmed_on_transition;
                }
              } else {
                total += p.confirmed_on_transition;
              }
            }
          });
        });
        return {
          id: `${idx}`,
          title: true,
          transitions: name,
          total: total || '-',
          ...data,
        };
      };
      const getMonthData = (transition, totalByMonth, blockName, idx, relatedId) => {
        const totalTransitionValue = Object.values(totalByMonth).reduce((s, v) => s + v, 0);
        const totalValue =
          transition.name === 'Left from the previous period'
            ? transition.periods[0].confirmed_on_transition
            : LAST_PERIOD.includes(transition.name)
            ? transition.periods[transition.periods.length - 1].confirmed_on_transition
            : transition.periods.reduce((sum, per) => sum + per.confirmed_on_transition, 0);
        const data = {};
        transition.periods.forEach(p => {
          const tooltip = getHiringTooltip(blockName, transition.name, p.month);
          data[p.month + p.year] = {
            tooltip: tooltip ? `${tooltip} = ${p.confirmed_on_transition} / ${p.moved_to_transition}` : '',
            percentage:
              p.confirmed_on_transition && p.moved_to_transition && !HIDE_PERCENTAGE.includes(transition.name)
                ? ` (${((p.confirmed_on_transition / p.moved_to_transition) * 100).toFixed(2)}%)`
                : '',
            value: p.confirmed_on_transition || '-',
          };
        });
        const totalTooltip = getHiringTooltip(blockName, transition.name, 'Total');
        return {
          id: `${idx}`,
          transitions: transition.name,
          total: {
            tooltip: totalTooltip ? `${totalTooltip} = ${totalValue} / ${totalTransitionValue}` : '',
            text: totalValue || '-',
            percentage:
              transition.name !== 'Left from the previous period' &&
              totalValue &&
              totalTransitionValue &&
              !DECLINED_COLLAPSED.includes(transition.name)
                ? ` (${((totalValue / totalTransitionValue) * 100).toFixed(2)}%)`
                : '',
          },
          relatedId: DECLINED_COLLAPSED.includes(transition.name) ? relatedId : '',
          ...data,
        };
      };
      const totalByMonth = {};
      el.transitions.forEach(transition => {
        transition.periods.forEach(p => {
          if (DECLINED_COLLAPSED.includes(transition.name)) return;
          totalByMonth[p.month + p.year] = p.moved_to_transition;
        });
      });
      return [
        getTitleData(el.transitions, totalByMonth, el.block_name, id),
        ...el.transitions.map((transition, idx) =>
          getMonthData(transition, totalByMonth, el.block_name, `${id}.${idx}`, `${id}.2`)
        ),
      ];
    })
    .flat();

export const hiringStatisticsColumnsMapper = periods => [
  {
    title: 'Total',
    key: 'total',
    dataIndex: 'total',
    width: 200,
    render: data =>
      typeof data === 'object' ? (
        <Tooltip label={data.tooltip}>
          {data.text}
          {data.percentage}
        </Tooltip>
      ) : (
        data
      ),
  },
  ...periods.map(el => ({
    title: el.month,
    key: el.month + el.year,
    dataIndex: el.month + el.year,
    width: 200,
    render: data =>
      typeof data === 'object' ? (
        <Tooltip label={data.tooltip}>
          {data.value}
          {data.percentage}
        </Tooltip>
      ) : (
        data
      ),
  })),
];

export const successRateColumnsMapper = resp => [
  {
    title: 'Average by months',
    dataIndex: 'average',
    key: 'average',
    width: 200,
    render: data => (data?.tooltip ? <Tooltip label={data.tooltip}>{data.text}</Tooltip> : data),
  },
  ...resp[0].periods.map(el => ({
    title: el.month,
    key: el.month + el.year,
    dataIndex: el.month + el.year,
    width: 200,
    render: data => (data?.tooltip ? <Tooltip label={data.tooltip}>{data.text}</Tooltip> : data),
  })),
];

const getSuccessTooltip = name => {
  switch (name) {
    case 'CVs Submitted':
      return SUCCESS_TOOLTIPS.SV_SUBMITTED;
    case 'Interviews Scheduled':
      return SUCCESS_TOOLTIPS.INTERVIEW_SCHEDULED;
    case 'Interviews Conducted':
      return SUCCESS_TOOLTIPS.INTERVIEW_SCHEDULED;
    default:
      return SUCCESS_TOOLTIPS.JOS_MADE;
  }
};

export const successRateDataMapper = resp =>
  resp.map(el => {
    const data = {};
    let average = 0;
    const tooltip = getSuccessTooltip(el.block_name);

    el.periods.forEach(p => {
      const amount =
        p.confirmed_on_transition && p.moved_to_transition
          ? ((p.confirmed_on_transition / p.moved_to_transition) * 100).toFixed(2)
          : 0;
      average += +amount;
      data[p.month + p.year] = {
        text: `${amount}%`,
        tooltip: `${tooltip} = ${p.confirmed_on_transition} / ${p.moved_to_transition}`,
      };
    });
    return {
      id: el.block_name,
      transition: el.block_name,
      average: {
        text: average && el.periods.length ? `${(average / el.periods.length).toFixed(2)}%` : '0%',
        tooltip: `Average (${resp[0].periods
          .map(e => e.month)
          .slice(0, 12)
          .join(', ')}${resp[0].periods.length > 12 ? '...' : ''})`,
      },
      ...data,
    };
  });
